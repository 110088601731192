<template>

    <div class="loader" v-if="loading" v-loading="loading"></div>

    <template v-if="order">

        <teleport to="head">
            <title>{{ txt[prefs.lang].order }} {{ order.placed }} – PLANTA</title>
        </teleport>

        <h2 v-if="user">{{ txt[prefs.lang].order }} №{{ order.id }}</h2>
        <h2 v-else>{{ txt[prefs.lang].order }} {{ txt[prefs.lang].from }} {{ order.placed }}</h2>

        <table>
            <tr><th>Дата</th><td>{{ order.placed }}</td></tr>
            <tr>
                <th>Статус</th>
                <td v-if="!order.canceled">
                    <template v-if="!order.tracking && !order.whaitavail">{{ txt[prefs.lang].in_process }}</template>
                    <template v-else-if="!order.tracking && order.whaitavail">{{ txt[prefs.lang].waiting }}</template>
                    <template v-else-if="order.tracking == '1'">{{ txt[prefs.lang].dispatched }}</template>
                    <template v-else-if="order.tracking == '2'">{{ txt[prefs.lang].delivered }}</template>
                    <template v-else-if="order.tracking">
                        {{ txt[prefs.lang].shipped }}:
                        <a v-if="order.delivery == 1" target="_blank" :href="'https://novaposhta.ua/tracking/?cargo_number='+order.tracking">{{ order.tracking }}</a>
                        <a v-if="order.delivery == 2" target="_blank" :href="'https://track.ukrposhta.ua/tracking_UA.html?barcode='+order.tracking">{{ order.tracking }}</a>
                    </template>
                </td>
                <td v-else>{{ txt[prefs.lang].canceled }}</td>
            </tr>
            <tr v-if="order.sum_to_pay > 2">
                <th>{{ txt[prefs.lang].to_pay }}</th>
                <td><b>{{ order.sum_to_pay }} грн</b> ({{ txt[prefs.lang].payment[order.payment_method] }})</td>
            </tr>
            <tr v-else-if="!order.canceled">
                <th>Оплата</th>
                <td>{{ txt[prefs.lang].paid }} <i class="el-icon-check"></i></td>
            </tr>
            <tr v-if="order.card">
                <th>Оплата</th>
                <td>
                    IBAN: {{ order.card[0] }}
<!--                    <el-input type="text" :value="order.card[0]+' ('+order.card[1]+')'" v-model="order.card[0]" readonly="readonly"></el-input>-->
                        &nbsp;<el-button plain round size="mini" icon="el-icon-document-copy" @click="copy(order.card[0])">{{ txt[prefs.lang].copy }}</el-button>
                    <br>{{ txt[prefs.lang].payment.i }} {{ txt.uk.order }} {{ txt.uk.from }} {{ order.placed }}
                    <br><el-button plain round size="mini" icon="el-icon-document" @click="requisites = order.card[1] + '\n' + txt[prefs.lang].payment.i + ' ' + txt.uk.order + ' ' + txt.uk.from + ' ' + order.placed +'\n'+txt[prefs.lang].to_pay+': '+order.sum_to_pay+' грн'">{{ txt[prefs.lang].payment.full }}</el-button>
<!--                    <textarea>-->
<!--                        {{ order.card[1] }}-->
<!--                        {{ txt[prefs.lang].payment.i }} {{ txt.uk.order }} {{ txt.uk.from }} {{ order.placed }}-->
<!--                    </textarea>-->
                </td>

            </tr>

        </table>

        <h3>{{ txt[prefs.lang].contacts }}</h3>
        <table>
            <tr><td>{{ txt[prefs.lang].firstname }}</td>
                <td>{{ order.contact.lastname }} {{ order.contact.firstname }} <template v-if="order.contact.middlename">{{ order.contact.middlename }}</template></td>
            </tr>
            <tr><td>{{ txt[prefs.lang].phone }}</td><td>+380{{ order.contact.phone.number }}</td></tr>
            <tr><td>{{ txt[prefs.lang].delivery.t }}</td><td>{{ txt[prefs.lang].delivery[order.delivery] }}</td></tr>
            <tr v-if="order.delivery > 0"><td>{{ txt[prefs.lang].delivery.type.t }}</td><td>{{ txt[prefs.lang].delivery.type[order.delivery_type] }}</td></tr>
            <tr v-if="order.np_city && order.delivery > 0"><td>{{ txt[prefs.lang].delivery.settlement }}</td><td>{{ order.np_city.present }}</td></tr>
            <tr v-if="order.np_wh && order.delivery == 1 && order.delivery_type == 1"><td>{{ txt[prefs.lang].delivery.wh }}</td><td>{{ order.np_wh['desc_'+prefs.lang] }}</td></tr>
            <tr v-else-if="order.delivery_type == 2"><td>{{ txt[prefs.lang].delivery.address }}</td><td>{{ order.address }}</td></tr>
            <tr v-if="order.comment"><td>{{ txt[prefs.lang].comment }}</td><td>{{ order.comment }}</td></tr>
            <tr v-if="order.commethod"><td>{{ txt[prefs.lang].commethod.t }}</td><td>{{ txt[prefs.lang].commethod[order.commethod].t }}<template v-if="order.comcontact">: {{ order.comcontact }}</template></td></tr>
        </table>

        <el-popconfirm v-if="!order.tracking && !order.canceled || user"
            :title=txt[prefs.lang].edit_conf
            :confirmButtonText=txt[prefs.lang].edit
            :cancelButtonText=txt[prefs.lang].no_thanks
            @confirm="changeContact"
        >
            <template #reference>
                <el-button icon="el-icon-edit-outline" size="small" plain round>{{ txt[prefs.lang].edit_contact }}</el-button>
            </template>
        </el-popconfirm>


        <h3>{{ txt[prefs.lang].products }}</h3>
        <table>
            <tr v-for="item in order.products">
                <td>{{ product(item)[prefs.lang].name }}</td>
                <td>{{ item.pivot.q }} шт</td>
                <template v-if="item.pivot.special_price !== null">
                    <td>
                        <span class="crossed">{{ item.price_old }}₴</span>
                        {{ item.pivot.special_price }}₴
                    </td>
                    <td>{{ item.pivot.special_price * item.pivot.q }}₴</td>
                </template>
                <template v-else-if="order.rate < 1 && product(item).min_rate < 1">
                    <td>
                        <span class="crossed">{{ item.price_old }}₴</span>
                        {{ Math.round(item.price_old * order.rate *100)/100 }}₴
                    </td>
                    <td>{{ Math.round(item.price_old * order.rate * item.pivot.q *100)/100 }}₴</td>
                </template>
                <template v-else>
                    <td>
                        {{ item.price_old }}₴
                    </td>
                    <td>{{ item.price_old * item.pivot.q }}₴</td>
                </template>
<!--                <td>{{ item.price_old.value }}</td>-->
<!--                <td>{{ item.price_old.value * order.rate }}</td>-->
<!--                <td>{{ item.price_old.value * order.rate * item.pivot.q }}</td>-->
            </tr>

            <tr><td>{{ txt[prefs.lang].disc_by_sum }}</td><td></td><td></td><td>{{ order.discount }}</td></tr>
            <tr><td>{{ txt[prefs.lang].loyalty_bonus }}</td><td></td><td></td><td>{{ order.credit }}</td></tr>
            <tr><td>{{ txt[prefs.lang].free_shipping }}</td><td></td><td></td>
                <td v-if="order.free_shipping > 0">🚚 🎉</td>
                <td v-else>–</td>
            </tr>
            <tr><td><b>{{ txt[prefs.lang].total }}</b></td><td></td><td></td><td><b>{{ order.total }}₴</b></td></tr>

        </table>


        <template v-if="!order.tracking && !order.canceled || user">

            <el-popconfirm
                v-if="!ch_order"
                :title=txt[prefs.lang].edit_conf
                :confirmButtonText=txt[prefs.lang].edit
                :cancelButtonText=txt[prefs.lang].no_thanks
                @confirm="changeOrder"
            >
                <template #reference>
                    <el-button :disabled="ch_order" icon="el-icon-sell" size="small" plain round>{{ txt[prefs.lang].edit_order }}</el-button>
                </template>
            </el-popconfirm>&nbsp;

            <el-button v-else @click="cancelEditing" icon="el-icon-close" size="small" plain round>{{ txt[prefs.lang].cancel_editing }}<template v-if="ch_order.hash != hash"> ({{ch_order.placed}})</template></el-button>&nbsp;

            <el-popconfirm
                v-if="!order.canceled && user"
                :title="txt[prefs.lang].cancel_order+'?'"
                :confirmButtonText=txt[prefs.lang].cancel
                :cancelButtonText=txt[prefs.lang].no_thanks
                iconColor="red"
                @confirm="cancel_order"
            >
                <template #reference>
                    <el-button icon="el-icon-circle-close" type="danger" size="small" plain round>{{ txt[prefs.lang].cancel_order }}</el-button>
                </template>
            </el-popconfirm>

            <el-popconfirm
                v-else-if="user && order.canceled"
                :title="txt[prefs.lang].decancel+'? РИЗИКОВАНО!!!'"
                :confirmButtonText=txt[prefs.lang].decancel
                :cancelButtonText=txt[prefs.lang].no_thanks
                iconColor="red"
                @confirm="decancel_order"
            >
                <template #reference>
                    <el-button icon="el-icon-refresh-left" type="danger" size="small" plain round>{{ txt[prefs.lang].decancel }}</el-button>
                </template>
            </el-popconfirm>&nbsp;

<!--                <el-button icon="el-icon-help" type="warning" plain round size="small">{{ txt[prefs.lang].complaint }}</el-button>-->

        </template>

        <el-popconfirm
            :title=txt[prefs.lang].duplicate
            :confirmButtonText=txt[prefs.lang].duplicate
            :cancelButtonText=txt[prefs.lang].no_thanks
            @confirm="duplicateOrder(1)"
        >
            <template #reference>
                <el-button icon="el-icon-document-copy" type="success" size="small" round>{{ txt[prefs.lang].duplicate }}</el-button>
            </template>
        </el-popconfirm>


    </template>

    <template v-else-if="from_mail">
        <h1>{{ txt[prefs.lang].pls_login }}:</h1>
        <p class="centered"><el-button @click="$emit('showLogin')" type="success" round>{{ txt[prefs.lang].login }}</el-button></p>
    </template>
    <template v-else><h2>{{ txt[prefs.lang].not_found }}</h2></template>


    <el-dialog v-model="requisites" :title=txt[prefs.lang].payment.full center>
        <el-input
            rows="8"
            type="textarea"
            :value=requisites
        />

        <template #footer>
            <span class="dialog-footer">
                <el-button @click="requisites = false" plain round>{{ txt[prefs.lang].cancel }}</el-button>
                <el-button type="success" round icon="el-icon-document-copy" @click="copy(requisites)">{{ txt[prefs.lang].copy }}</el-button>
            </span>
        </template>
    </el-dialog>

</template>


<script>
import useCart from '@/use/cart'
import { txt } from '@/use/txt'
import { useQuery, useResult, useMutation } from '@vue/apollo-composable'
import orderQuery from '../graphql/order.query.gql'
import order_Query from '../graphql/order_.query.gql'
import { ref, watch } from "vue";
import { gql } from '@apollo/client'
import router from '@/router/index'
// import { ElNotification } from 'element-plus'
// import { ElLoading } from 'element-plus'; // ElLoading.service()

export default {
    setup(props, context) {

        const { me, user, cart, addToCart, prefs, products, ch_order: ch_order, order_items, message } = useCart()

        let from_mail = router.currentRoute.value.query.from_mail

        if (!me.value && !user.value) context.emit('showLogin')
        // const product = computed((item) => 'test')

        const product = (item) => products.value.find(i => i.id == item.id)

        // if (props.id) console.log(props.id)
        // const vars = ref({ id: props.id })
        const { result, error, loading, refetch } = user.value ? useQuery(order_Query, { id: props.id }, { fetchPolicy: "network-only" }) : useQuery(orderQuery, { id: props.id }, { fetchPolicy: "network-only" })
        const order = useResult(result, null, data => data.order)

        watch(me, () => {
            refetch({ id: props.id })
        })

        function changeContact() {
            router.push({ path: '/order/'+props.id+'/contact' })
        }

        function changeOrder() {
            duplicateOrder()
            localStorage.setItem('ch_order', JSON.stringify(order.value))
            ch_order.value = order.value
            localStorage.setItem('order_items', JSON.stringify(cart.items))
            order_items.value = JSON.parse(localStorage.getItem('order_items'))
            router.push({ path: '/cart' })
        }

        function duplicateOrder(to_cart = false) {

            cart.emptyCart()

            order.value.products.forEach(function(item) {

                // let prod = products.value.find(i => i.id == item.id)
                // let opts = JSON.parse(JSON.stringify(prod.options))
                let prod = JSON.parse(JSON.stringify(products.value.find(i => i.id == item.id)))

                // list of enabled options
                if (item.pivot.options) {
                    item.pivot.options.split(',').forEach(function(id) {
                        prod.options.find(i => i.id == id).enabled = true
                    })
                }

                // console.log(prod)
                // return
                addToCart(prod, item.pivot.q)
                // cart.items[prod.id] = {
                //     id: prod.id,
                //     url: prod.url,
                //     q: Number(item.pivot.q),
                //     options: opts
                // }
            })

            if (to_cart) router.push({ path: '/cart' })
        }

        const { mutate: cancelOrder } = useMutation(gql`
              mutation($id: ID!) {
                  order: cancelOrder_(id: $id) {
                      id
                      canceled
                      tracking
                  }
              }
          `)

        function cancel_order() {
            cancelOrder({ id: props.id }).then(r => {
                let order = r.data.order
                if (!order) message(txt[prefs.lang].error, txt[prefs.lang].wrong, 'error')
                else if (order.canceled) message(txt[prefs.lang].canceled, txt[prefs.lang].order+' №'+order.id+txt[prefs.lang].order_canceled, 'info')
                else if (!order.canceled) message(txt[prefs.lang].error, txt[prefs.lang].order+' №'+props.id+txt[prefs.lang].cant_change, 'warning')
            })
        }

        const { mutate: decancelOrder } = useMutation(gql`
            mutation($id: ID!) {
                order: decancelOrder(id: $id) {
                    id
                    canceled
                    tracking
                }
            }
        `)

        function decancel_order() {
            decancelOrder({ id: props.id }).then(r => {
                let order = r.data.order
                if (!order) message(txt[prefs.lang].error, txt[prefs.lang].wrong, 'error')
                else if (!order.canceled) message(txt[prefs.lang].decanceled, txt[prefs.lang].order+' №'+order.id+txt[prefs.lang].decanceled, 'info')
                else if (order.canceled) message(txt[prefs.lang].error, txt[prefs.lang].order+' №'+props.id+txt[prefs.lang].wrong, 'warning')
            })
        }

        function cancelEditing() {
            localStorage.removeItem('ch_order')
            ch_order.value = null
            localStorage.removeItem('order_items')
            order_items.value = null
        }

        function copy(v) {
            navigator.clipboard.writeText(v).then(function() {
                message(txt[prefs.lang].copied, v, 'success')
            })
        }

        const requisites = ref(false)

        return { me, user, prefs, txt, order, loading, product, cancel_order, changeOrder, cancelEditing, duplicateOrder, changeContact, ch_order, decancel_order, copy, requisites, from_mail }
    },
    props: {
        id: [String, Number]
    }
}
</script>